export const formatTimestamp = (timestamp) => {
    if (!timestamp) return 'N/A';
    const date = new Date(timestamp);
    if (isNaN(date.getTime())) return timestamp;
    return date.toLocaleString('de-DE', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
    });
};

export const timeFormat = (time) => {
    const date = new Date(time);
    const day = String(date.getDate()).padStart(2, '0'); // Get day and pad with zero if needed
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month (0-indexed, so add 1) and pad
    const year = date.getFullYear(); // Get full year
    return `${day}/${month}/${year}`; // Format as DD/MM/YYYY
}

/**
 * Get URL params;
 */
export const getParams = (params) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(params);
}

// helpers/utils.js

// export const formatTimestamp = (timestamp) => {
//     const date = new Date(timestamp);
//
//     // Get day, month, year, hours, minutes, and seconds
//     const day = String(date.getDate()).padStart(2, '0'); // Pad single digit days
//     const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
//     const year = date.getFullYear();
//     const hours = String(date.getHours()).padStart(2, '0'); // Pad single digit hours
//     const minutes = String(date.getMinutes()).padStart(2, '0'); // Pad single digit minutes
//     const seconds = String(date.getSeconds()).padStart(2, '0'); // Pad single digit seconds
//
//     return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
// };


// Function to calculate chat duration
export const calculateChatDuration = (entryData) => {
    if (!entryData || !entryData.created_at || !entryData.chatLog || entryData.chatLog.length === 0) {
        return "N/A"; // Return a fallback if data is incomplete
    }

    const startTime = new Date(entryData.created_at).getTime(); // Start time (milliseconds)
    // const endTime = new Date(entryData.chatLog[entryData.chatLog.length - 1].timestamp).getTime(); // End time (milliseconds)

    // TEMP Because BE switched order of data
    const endTime = new Date(entryData.chatLog[0].timestamp).getTime(); // End time (milliseconds)

    const durationMs = endTime - startTime; // Duration in milliseconds

    // Convert milliseconds to a more readable format (minutes, seconds)
    const durationSeconds = Math.floor(durationMs / 1000); // Convert to seconds
    const minutes = Math.floor(durationSeconds / 60);
    const seconds = durationSeconds % 60;

    // Return formatted duration
    return `${minutes}m ${seconds}s`;
};


export const formatDuration = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    const parts = [];
    if (hours > 0) parts.push(`${hours}h`);
    if (minutes > 0) parts.push(`${minutes}m`);
    if (remainingSeconds > 0 || parts.length === 0) parts.push(`${remainingSeconds}s`);
    return parts.join(' ');
};

export const processLogEntries = (entries) => {
    const groupedEntries = entries.reduce((acc, entry) => {
        if (!acc[entry.session_id]) {
            acc[entry.session_id] = [];
        }
        acc[entry.session_id].push(entry);
        return acc;
    }, {});

    const processedEntries = [];
    Object.entries(groupedEntries).forEach(([sessionId, sessionEntries]) => {
        const sortedEntries = sessionEntries.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
        const startTime = new Date(sortedEntries[0].created_at);
        const endTime = new Date(sortedEntries[sortedEntries.length - 1].created_at);
        const duration = (endTime - startTime) / 1000; // duration in seconds

        const summaryEntry = {
            id: `summary-${sessionId}`,
            session_id: sessionId,
            message_type: 'SUMMARY',
            created_at: startTime,
            message: `🕒 ${formatTimestamp(startTime)} 🔄 ${sortedEntries.length} ⏱ ${formatDuration(duration)}`,
        };

        processedEntries.push(summaryEntry, ...sortedEntries);
    });

    return processedEntries.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
};


export const resetUrlParams = () => {
    const url = new URL(window.location); // Get the current URL
    url.search = ''; // Remove all query parameters
    window.history.pushState({}, '', url); // Update the URL without query parameters
};

