import { Checkbox, FormControl, InputLabel, ListItemText, OutlinedInput, Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import React, { useState } from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const names = [
    'Show All',
    'Cart Issues',
    'Refund Request',
    'Login Issues',
    'Shipping Delay',
    'Payment Inquiry',
    'Order Tracking'
];

export default function CategoryFilter({ filter }) {
    const [filterCategory, setFilterCategory] = useState([]);

    const setCategoryFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterCategory(event.target.value);
    }

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setFilterCategory(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <FormControl variant="filled" size="small" className='form-control'>
            <InputLabel id="demo-simple-select-filled-label">Filter categories</InputLabel>
            <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                value={filterCategory}
                multiple
                onChange={handleChange}
                MenuProps={MenuProps}
                renderValue={(selected) => selected.join(', ')}
            >
                {names.map((name) => (
                    <MenuItem key={name} value={name}>
                        <Checkbox checked={filterCategory.includes(name)} />
                        <ListItemText primary={name} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
