import { Chip, IconButton, Stack } from "@mui/material";
import { calculateChatDuration, formatTimestamp } from "../../../helpers/utils";
import React, { useState } from "react";
import AssistantPhotoIcon from "@mui/icons-material/AssistantPhoto";
import OutlinedFlagIcon from "@mui/icons-material/OutlinedFlag";
import CheckIcon from '@mui/icons-material/Check';
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ShareIcon from '@mui/icons-material/Share';
import { toast } from "react-toastify";

const options = [
    {label: 'Cart Issues', color: 'white', back: '#aa1e1e'},
    {label: 'Refund Request', color: 'white', back: '#fbad49'},
    {label: 'Login Issues', color: 'white', back: 'black'},
    {label: 'Shipping Delay', color: 'black', back: 'yellow'},
    {label: 'Payment Inquiry', color: 'white', back: 'blue'},
    {label: 'Order Tracking', color: 'white', back: 'grey'},
];

export default function MetaDataAccordion(
    {
        entryData,
        sessionResult,
        onResultClick,
        onCategoryEditClick,
        categories,
        sidebarSize,
        onPrevEntryClick,
        onNextEntryClick
    }) {
    const [isOpen, setIsOpen] = useState(false);
    const toggleAccordion = () => setIsOpen(!isOpen);

    // Handle tagged - TEMP / Demo
    const [isTagged, setIsTagged] = useState();
    const handleTagged = () => { setIsTagged(() => !isTagged) };

    const copySessionID = (id) => {
        navigator.clipboard.writeText(id).then(() => {
            toast.success('Session ID copied to clipboard!', {
                position: "bottom-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }).catch(() => {
            toast.error('Failed to copy session ID.', {
                position: "bottom-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        });
    };

    const copyEntryURL = () => {
        const url = window.location.href; // Get the current URL
        navigator.clipboard.writeText(url)  // Copy the URL to clipboard
            .then(() => {
                toast.success('Deep link copied to clipboard!', {
                    position: "bottom-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            })
            .catch((error) => {
                toast.error('Failed to copy deep link.', {
                    position: "bottom-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            });
    }

    return (
        <div className="meta-data" style={{maxHeight: isOpen ? '300px' : '80px',}}>
            <div className="meta-data-header">

                <div className="headline">
                    <span className="session-id" title="Click to copy complete session ID"
                          onClick={() => copySessionID(entryData.session_id)}
                    >
                        Conversation: <b> {entryData.session_id.slice(-6)}</b>
                    </span>
                    <span className="status" onClick={onResultClick}>
                        <CheckIcon sx={{color: 'green', fontSize: '16px'}}/>
                        {sessionResult}
                    </span>

                    <div className="navigation">
                        <IconButton size="small" onClick={onPrevEntryClick}>
                            <ArrowBackIosNewIcon fontSize="inherit" />
                        </IconButton>
                        <IconButton size="small" onClick={onNextEntryClick}>
                            <ArrowBackIosNewIcon fontSize="inherit" />
                        </IconButton>
                    </div>

                    <div className="share-icon">
                        <IconButton size="small" onClick={copyEntryURL}>
                            <ShareIcon fontSize="inherit" />
                        </IconButton>
                    </div>

                    {isTagged ? (
                        <IconButton className="tagged" onClick={handleTagged}>
                            <AssistantPhotoIcon fontSize="small" style={{color: 'black'}}/>
                        </IconButton>
                    ) : (
                        <IconButton className="tagged" onClick={handleTagged}>
                            <OutlinedFlagIcon fontSize="small" style={{color: 'black'}}/>
                        </IconButton>
                    )}
                </div>

                <div className="category-chips">
                    <Stack direction="row" spacing={1} style={{display: "flex", alignItems: "center"}}>
                        {categories.map((value) => {
                            const option = options.find(opt => opt.label === value);
                            return (
                                <Chip
                                    key={value}
                                    label={value}
                                    size="small"
                                    style={{
                                        color: option ? option.color : 'default',
                                        backgroundColor: option ? option.back : 'default'
                                    }}
                                />
                            );
                        })}

                        <p onClick={onCategoryEditClick} style={{cursor: "pointer"}}>edit</p>
                    </Stack>
                </div>
            </div>

            <div className="meta-data-content" style={{opacity: isOpen ? 1 : 0, maxHeight: isOpen ? '200px' : '0'}}>
                {sidebarSize ? (
                    <>
                        <div className="row">
                            <span className='key'>User ID: </span>
                            <span>{entryData.userId}</span>
                        </div>
                        <div className="row">
                            <span className='key'>Language: </span>
                            <span>{/*entryData.custom_fields.language*/}</span>
                        </div>
                        <div className="row">
                            <span className='key'>Start: </span>
                            <span>{formatTimestamp(entryData.created_at)}</span>
                        </div>
                        <div className="row">
                            <span className='key'>End: </span>
                            <span>{/*formatTimestamp(entryData.custom_fields.end)*/}</span>
                            <span>{formatTimestamp(entryData.chatLog[entryData.chatLog.length - 1].timestamp)}</span>
                        </div>
                        <div className="row">
                            <span className='key'>Duration: </span>
                            <span>{/*entryData.custom_fields.duration*/}</span>
                            <span>{calculateChatDuration(entryData)}</span>
                        </div>
                        <div className="row">
                            <span className='key'>Interactions: </span>
                            <span>{entryData.chatLog.length}</span>
                            <span>{/*entryData.custom_fields.interactions*/}</span>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="row">
                            <div className='cell'>
                                <span className='key'>Start: </span>
                                <span>{formatTimestamp(entryData.created_at)}</span>
                            </div>
                            <div className='cell'>
                                <span className='key'>User ID: </span>
                                <span>{entryData.userId}</span>
                            </div>
                        </div>

                        <div className="row">
                            <div className='cell'>
                                <span className='key'>End: </span>
                                <span>{/*formatTimestamp(entryData.custom_fields.end)*/}</span>
                                <span>{formatTimestamp(entryData.chatLog[entryData.chatLog.length - 1].timestamp)}</span>
                            </div>
                            <div className='cell'>
                            <span className='key'>Language: </span>
                                <span>{/*entryData.custom_fields.language*/}</span>
                            </div>
                        </div>

                        <div className="row">
                            <div className='cell'>
                                <span className='key'>Duration: </span>
                                <span>{/*entryData.custom_fields.duration*/}</span>
                                <span>{calculateChatDuration(entryData)}</span>
                            </div>
                            <div className='cell'>
                                <span className='key'></span>
                                <span></span>
                            </div>
                        </div>

                        <div className="row">
                            <div className='cell'>
                                <span className='key'>Interactions: </span>
                                <span>{/*entryData.custom_fields.interactions*/}</span>
                                <span>{entryData.chatLog.length}</span>
                            </div>
                            <div className='cell'>
                                <span className='key'></span>
                                <span></span>
                            </div>
                        </div>
                    </>
                    )
                }
            </div>

            <span className="toggle-button" onClick={toggleAccordion}>
                <span>{isOpen ? 'Show Less' : 'Show More'}</span>
                {isOpen ? <ExpandLessIcon fontSize="small"/> : <ExpandMoreIcon fontSize="small"/>}
            </span>
        </div>
    )
}
