import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import React from "react";

export default function NoDataView({filterText}) {
    return (
        <div className="no-data-view">
            {filterText && <SentimentDissatisfiedIcon className="icon"/>}
            <p className="text">{filterText ? 'Nothing found' : 'Select an App'}</p>
        </div>
    )
}
