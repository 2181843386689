import { formatTimestamp } from "../../helpers/utils";

export default function Chat({entryData}) {
    return (
        <div className="chat-area">
            {entryData.chatLog.map((entry, index) => (
                <div key={index} style={{
                    marginBottom: '10px',
                    display: 'flex',
                    flexDirection: entry.sender === 'USER' ? 'row-reverse' : 'row'
                }}>
                    <div style={{
                        padding: '10px',
                        borderRadius: '10px',
                        backgroundColor: entry.sender === 'USER' ? '#e0f7fa' : '#ffe0b2',
                        maxWidth: '80%',
                        wordWrap: 'break-word',
                        position: 'relative'
                    }}>
                        <strong><small>{entry.sender === 'USER' ? 'User' : 'Bot'}:</small></strong>
                        <p style={{margin: '5px 0'}}>{entry.message}</p>
                        <small style={{color: '#666'}}>{formatTimestamp(entry.timestamp)}</small>
                    </div>
                </div>
            ))}
        </div>
    )
}
