// React
import { useEffect, useRef, useState } from "react";

import MetaData from "./meta-data/meta-data";
import Chat from "./chat";

export default function Sidebar({entryData, onPrevEntryClick, onNextEntryClick}) {
    const [isSidebarSmall, setIsSidebarSmall] = useState(false);
    const sidebarRef = useRef(null);

    useEffect(() => {
        const sidebarElement = sidebarRef.current;
        if (!sidebarElement) return;

        const observer = new ResizeObserver(entries => {
            for (let entry of entries) {
                const width = entry.contentRect.width;
                setIsSidebarSmall(width < 450);
            }
        });

        observer.observe(sidebarElement);

        return () => observer.disconnect();
    }, []);

    return (
        <>
            <div ref={sidebarRef}>
                <MetaData
                    entryData={entryData}
                    sidebarSize={isSidebarSmall}
                    onPrevEntryClick={onPrevEntryClick}
                    onNextEntryClick={onNextEntryClick}
                />
            </div>

            <Chat entryData={entryData}/>
        </>
    )
}
