// React
import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from "react-router-dom";

// Own
import App from './App';
import ErrorPage from "./components/error-page";
import './index.scss';
import AppSettings from "./views/admin/app-settings";
import UserManagement from "./views/admin/user-management";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <ErrorPage />
    },
    {
        path: "user-management",
        element: <UserManagement />,
        errorElement: <ErrorPage />
    },
    {
        path: "settings",
        element: <AppSettings />,
        errorElement: <ErrorPage />
    },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
    <RouterProvider router={router}>
        <App />
    </RouterProvider>
);
