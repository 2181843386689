import React, { useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from '@mui/icons-material/Close';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

const options = [
    { label: 'Resolved', icon: <CheckIcon sx={{ color: 'green', fontSize: '18px' }} /> },
    { label: 'Unresolved', icon: <CloseIcon sx={{ color: 'red', fontSize: '18px' }} /> },
    { label: 'Not defined', icon: <QuestionMarkIcon sx={{ color: 'grey', fontSize: '16px' }} /> }
];

export function ResultUpdateDialog({onClose, value: valueProp, open}) {
    const [value, setValue] = useState(valueProp);

    return (
        <Dialog
            sx={{'& .MuiDialog-paper': {width: '70%', maxHeight: 335}}}
            maxWidth="xs"
            open={open}
        >
            <DialogTitle>Update Session Result</DialogTitle>
            <DialogContent dividers>
                <RadioGroup
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                >
                    {options.map((option) => (
                        <FormControlLabel
                            key={option.label}
                            value={option.label}
                            control={<Radio />}
                            label={
                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                    {option.icon}
                                    <span style={{ marginLeft: 4 }}>{option.label}</span>
                                </span>
                            }
                        />
                    ))}
                </RadioGroup>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    Cancel
                </Button>
                <Button onClick={() => onClose(value)}>Ok</Button>
            </DialogActions>
        </Dialog>
    );
}

ResultUpdateDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
};
