import { useState, useEffect, useRef } from 'react';
import { getColumns } from "./columns";

/**
 * Custom hook to manage table column configurations based on the table's width.
 *
 * This hook:
 * - Observes the table's width using a `ResizeObserver` on a `tableRef`.
 * - Updates the `tableWidth` state whenever the table's width changes.
 * - Sets the `columns` state dynamically based on the current table width,
 *   filtering columns as specified in the `getColumns` function from the `columns` file.
 *
 * @returns {Object} An object containing:
 *   - `tableRef` (ref): A reference to the table DOM element, allowing for width observation.
 *   - `columns` (Array): An array of column configurations filtered based on table width.
 *
 * Usage:
 * Attach `tableRef` to the table component's `ref` attribute, and use `columns`
 * to render columns dynamically based on the current table width.
 *
 * Note:
 * The `tableRef.current` is saved to a `currentTableRef` variable within the effect to avoid
 * stale references in the cleanup function, ensuring the correct DOM node is used
 * even if `tableRef.current` changes during re-renders.
 */
export const useTableColumns = () => {
    const [tableWidth, setTableWidth] = useState(0);
    const [columns, setColumns] = useState([]);
    const tableRef = useRef(null);

    useEffect(() => {
        function updateColumns() {
            if (tableRef.current) {
                const width = tableRef.current.offsetWidth;
                setTableWidth(width);
                setColumns(getColumns(width));
            }
        }

        // Save the current value of `tableRef.current` to a variable
        const currentTableRef = tableRef.current;

        const resizeObserver = new ResizeObserver(() => {
            updateColumns();
        });

        if (currentTableRef) {
            resizeObserver.observe(currentTableRef);
        }

        return () => {
            if (currentTableRef) resizeObserver.unobserve(currentTableRef);
        };
    }, [tableWidth]);

    return { tableRef, columns };
};
