import Header from "../../components/header/header";
import React from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

const AppSettings = () => {
    const navigate = useNavigate();

    return (
        <>
            <Header/>

            <Button variant={"text"} className="go-back-button"
                    startIcon={<KeyboardArrowLeftIcon />}
                    onClick={() => navigate("/")}>
                Go back
            </Button>
        </>
    )
}

export default AppSettings;
