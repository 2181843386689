// React
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Own
import Sidebar from "./components/sidebar/sidebar";
import Header from "./components/header/header";
import Filters from "./components/filters/filters";
import Table from "./components/table/table";
import { resetUrlParams } from "./helpers/utils";

const LoggiApp = () => {
    const [selectedAppId, setSelectedAppId] = useState();
    const [selectedEntry, setSelectedEntry] = useState(null);
    const [logEntries, setLogEntries] = useState([]);

    // Ref to store the latest selectedEntry
    // because keydown listener can't work with react state out of the box
    const selectedEntryRef = useRef(selectedEntry);

    // Reset data on APP Change
    useEffect(() => {
        setLogEntries([]);
        setSelectedEntry(null);
        resetUrlParams();
    }, [selectedAppId]);

    // Update ref whenever selectedEntry changes
    useEffect(() => {
        selectedEntryRef.current = selectedEntry;
    }, [selectedEntry]);

    // Set up keyboard navigation once when logEntries changes
    useEffect(() => {
        if (!logEntries) return;

        const handleKeyDown = (event) => {
            if (event.key === "ArrowLeft") {
                getLog("prev");
            } else if (event.key === "ArrowRight") {
                getLog("next");
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => window.removeEventListener("keydown", handleKeyDown);

    }, [logEntries]);

    // Function to handle navigation
    const getLog = useCallback((direction) => {
        const entryIndex = logEntries.findIndex(entry => entry.session_id === selectedEntryRef.current.session_id);

        if (direction === 'prev' && entryIndex > 0) {
            setSelectedEntry(logEntries[entryIndex - 1]);
        } else if (direction === 'next' && entryIndex < logEntries.length - 1) {
            setSelectedEntry(logEntries[entryIndex + 1]);
        }
    }, [logEntries]);

    // Filtering state - WIP
    const [filterText, setFilterText] = useState('');

    /**
     * Handle Sidebar resize
     */
    const sidebarRef = useRef(null);
    const [isResizing, setIsResizing] = useState(false);
    const handleMouseDown = () => setIsResizing(true);

    useEffect(() => {
        const handleMouseMove = (e) => {
            if (!isResizing) return;

            // Calculate new width with a max of 50% of the screen width
            const maxWidth = window.innerWidth * 0.5;
            const newWidth = Math.min(window.innerWidth - e.clientX, maxWidth);

            // Set min and max width
            if (newWidth >= 150 && newWidth <= maxWidth) {
                sidebarRef.current.style.width = `${newWidth}px`;
            }
        };

        const handleMouseUp = () => {
            setIsResizing(false);
            document.body.classList.remove("no-select");
        };

        if (isResizing) {
            document.body.classList.add("no-select");
        }

        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);

        return () => {
            document.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("mouseup", handleMouseUp);
            document.body.classList.remove("no-select"); // Clean up if effect re-runs
        };
    }, [isResizing]);


    return (
        <>
            <Header selectedApp={(appId) => setSelectedAppId(appId)}/>

            <div className="content-wrapper">
                <div className="main-content">
                    {selectedAppId &&
                        <Filters onFilterText={(text) => setFilterText(text)} />
                    }

                    <Table
                        selectedAppId={selectedAppId}
                        onRowClick={(entryData) => setSelectedEntry(entryData)}
                        filterText={filterText}
                        logEntriesCallback={(entries) => setLogEntries(entries)}
                        selectedEntryChanged={selectedEntry}
                    />
                </div>

                <div className="sidebar" ref={sidebarRef}>
                    <div className="resizer" onMouseDown={handleMouseDown}/>

                    {selectedEntry &&
                        <Sidebar
                            entryData={selectedEntry}
                            onPrevEntryClick={() => getLog("prev")}
                            onNextEntryClick={() => getLog("next")}
                        />
                    }
                </div>
            </div>

            <ToastContainer/>
        </>
    );
};

export default LoggiApp;
