import React from "react";

import { timeFormat } from "../../helpers/utils";
import AssistantPhotoIcon from "@mui/icons-material/AssistantPhoto";
import OutlinedFlagIcon from "@mui/icons-material/OutlinedFlag";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

export const getColumns = (tableWidth) => [
    {
        name: 'Session ID',
        selector: row => row.session_id.length > 6 ? row.session_id.slice(-6) : row.session_id,
        width: "100px"
    },
    {
        name: 'Date',
        selector: row => row.created_at,
        sortable: true,
        width: "100px",
        cell: row => {
            const formattedDate = timeFormat(row.created_at);
            // const duration = row.custom_fields.duration ? `${row.custom_fields.duration}` : '';
            const duration = '';

            return (
                <div style={{pointerEvents: 'none'}}>
                    {formattedDate}
                    {tableWidth < 900 && duration && (
                        <div style={{fontSize: 'smaller', color: '#777'}}>
                            {duration}
                        </div>
                    )}
                </div>
            );
        },
    },
    // {
    //     name: 'Duration',
    //     sortable: true,
    //     width: "100px",
    //     selector: row => row.custom_fields.duration,
    //     omit: tableWidth < 900,
    // },
    {
        name: 'Conversation',
        selector: row => row.chatLog,
        grow: 4,
        cell: row => (
            <div style={{cursor: 'pointer', pointerEvents: 'none'}}>
                {row.chatLog[1] && (
                    <div style={{color: '#0f7785'}}>
                        {row.chatLog[1].message.length > 100
                            ? row.chatLog[1].message.substring(0, 100) + '...'
                            : row.chatLog[1].message}
                    </div>
                )}
                {row.chatLog[2] && (
                    <div style={{color: '#e39b3a'}}>
                        {row.chatLog[2].message.length > 100
                            ? row.chatLog[2].message.substring(0, 100) + '...'
                            : row.chatLog[2].message} {`(${row.chatLog.length})`}
                    </div>
                )}
            </div>

        ),
        wrap: true,
        maxHeight: "50px",
    },
    // {
    //     name: 'Category',
    //     selector: row => row.custom_fields.topic,
    //     grow: 2,
    // },
    // {
    //     name: 'Language',
    //     selector: row => row.custom_fields.language,
    //     omit: tableWidth < 900,
    //     width: "90px"
    // },
    // {
    //     name: 'Result',
    //     selector: row => (
    //         row.custom_fields.result === 'Resolved' ?
    //             <CheckCircleOutlineOutlinedIcon
    //                 fontSize="small"
    //                 style={{color: 'green', pointerEvents: 'none'}}
    //             />
    //             : row.custom_fields.result === 'Unresolved' ?
    //                 <CancelOutlinedIcon
    //                     fontSize="small"
    //                     style={{color: 'red', pointerEvents: 'none'}}
    //                 />
    //                 :
    //                 <HelpOutlineOutlinedIcon
    //                     fontSize="small"
    //                     style={{color: 'grey', pointerEvents: 'none'}}
    //                 />
    //     ),
    //     omit: tableWidth < 900,
    //     width: "70px"
    // },
    {
        name: '',
        selector: row => (
            row.tagged ? (
                <AssistantPhotoIcon
                    fontSize="small"
                    style={{color: 'black', pointerEvents: 'none'}}
                />
            ) : (
                <OutlinedFlagIcon
                    fontSize="small"
                    style={{color: 'grey', pointerEvents: 'none'}}
                />
            )
        ),
        omit: tableWidth < 900,
        width: "50px"
    },
    // {
    //     name: '',
    //     width: "110px",
    //     selector: row => (
    //         <div style={{
    //             display: 'flex',
    //             justifyContent: 'space-between',
    //             width: '80px'
    //         }}>
    //             <div style={{width: '15px', paddingTop: '2px'}}>
    //                 {row.custom_fields.language}
    //             </div>
    //             <div>
    //                 {row.custom_fields.result === 'Resolved' ?
    //                     <CheckCircleOutlineOutlinedIcon
    //                         fontSize="small"
    //                         style={{color: 'green', pointerEvents: 'none'}}
    //                     />
    //                     : row.custom_fields.result === 'Unresolved' ?
    //                         <CancelOutlinedIcon
    //                             fontSize="small"
    //                             style={{color: 'red', pointerEvents: 'none'}}
    //                         />
    //                         :
    //                         <HelpOutlineOutlinedIcon
    //                             fontSize="small"
    //                             style={{color: 'grey', pointerEvents: 'none'}}
    //                         />}
    //             </div>
    //             <div>
    //                 {row.tagged ? (
    //                     <AssistantPhotoIcon
    //                         fontSize="small"
    //                         style={{color: 'black', pointerEvents: 'none'}}
    //                     />
    //                 ) : (
    //                     <OutlinedFlagIcon
    //                         fontSize="small"
    //                         style={{color: 'grey', pointerEvents: 'none'}}
    //                     />
    //                 )}
    //             </div>
    //         </div>
    //     ),
    //     omit: tableWidth > 900,
    // }
];
