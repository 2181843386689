import { FormControl, InputLabel, Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import React, { useState } from "react";

export default function LanguageFilter({ filter }) {
    const [filterLanguage, setFilterLanguage] = useState('all');

    const setResultLanguage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterLanguage(event.target.value);
    }

    return (
        <FormControl variant="filled" size="small" className='form-control'>
            <InputLabel id="demo-simple-select-filled-label">Filter Languages</InputLabel>
            <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                value={filterLanguage}
                onChange={setResultLanguage}
            >
                <MenuItem value="all">Show All</MenuItem>
                <MenuItem value="en">EN</MenuItem>
                <MenuItem value="de">DE</MenuItem>
            </Select>
        </FormControl>
    )
}
