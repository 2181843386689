import Header from "../../components/header/header";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Settings from '@mui/icons-material/Settings';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import Button from "@mui/material/Button";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useNavigate } from "react-router-dom";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    IconButton,
    Radio,
    RadioGroup,
    TextField
} from "@mui/material";

const usersData = [
    {
        userId: "BP159753",
        userName: "LeoS",
        added: "31.10.2024",
        role: "Admin",
    },
    {
        userId: "BP741258",
        userName: "ImkeG",
        added: "31.10.2024",
        role: "Admin",
    },
    {
        userId: "BP963258",
        userName: "AlmuP",
        added: "31.10.2024",
        role: "Admin",
    },
    {
        userId: "BP852147",
        userName: "MaxH",
        added: "31.10.2024",
        role: "Admin",
    },
    {
        userId: "BP761852",
        userName: "AndriiB",
        added: "31.10.2024",
        role: "Admin",
    },
    {
        userId: "BP798645",
        userName: "JosipK",
        added: "31.10.2024",
        role: "Admin",
    }
]

const getColumns = (onRowClicked) => [
    {
        name: "User Name",
        selector: row => row.userName,
        grow: 2
    },
    {
        name: "User ID",
        selector: row => row.userId,
        grow: 2
    },
    {
        name: "Added",
        selector: row => row.added,
        grow: 2
    },
    {
        name: "Rights",
        selector: row => row.role,
        width: "100px"
    },
    {
        name: "Edit user",
        center: "true",
        width: "80px",
        cell: row => (
            <IconButton
                size={"small"}
                onClick={() => {onRowClicked(row)}}
                edge="end"
            >
                <Settings />
            </IconButton>
        )
    }
]

const UserManagement = ({onClose, value: valueProp, open}) => {
    const navigate = useNavigate();
    const [selectedUser, setSelectedUser] = useState();
    const [userName, setUserName] = useState("");
    const [userRole, setUserRole] = useState("");
    const [users, setUsers] = useState(usersData);
    const [value, setValue] = useState(valueProp);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const onRowClicked = (row) => {
        setSelectedUser(row)
        setUserName(row.userName);
        setUserRole(row.role);
    }

    const handleUserNameChange = (event) => {
        setUserName(event.target.value);
    };

    const handleUserRoleChange = (event) => {
        setUserRole(event.target.value);
    };

    useEffect(() => {
        if (selectedUser) {
            setUserName(selectedUser.userName);
        }
    }, [selectedUser]);

    const saveUserChanges = () => {
        const updatedUsers = users.map((user, index) => {
            if (user.userId === selectedUser.userId) {
                return {
                    ...user,
                    userName: userName,
                    role: userRole,
                }
            } else {
                return user;
            }
        });

        setUsers(updatedUsers);
    }

    const openDeleteDialogWindow = () => {
        setOpenDeleteDialog(true);
    }

    const deleteUser = () => {
        setUsers(users.filter(user => user.userId !== selectedUser.userId));
        setSelectedUser(null);
        setOpenDeleteDialog(false);
    }

    const columns = getColumns(onRowClicked);

    return (
        <>
            <Header/>

            <div className="user-management">
                <Button variant={"text"} className="go-back-button"
                        startIcon={<KeyboardArrowLeftIcon />}
                        onClick={() => navigate("/")}>
                    Go back
                </Button>

                <h2 className="headline">User management</h2>

                <div className="table-wrapper">
                    <div className="user-management-table">
                        <DataTable
                            data={users}
                            columns={columns}
                        />
                        <Button variant={"outlined"} className="add-button"
                                startIcon={<AddCircleOutlineIcon/>}>
                            Add user
                        </Button>
                    </div>

                    {selectedUser &&
                        <div className="edit-user-area">
                            <>
                                <TextField id="outlined-basic"
                                           label="User Name"
                                           variant="outlined"
                                           value={userName}
                                           className="input"
                                           onChange={handleUserNameChange}
                                />

                                <TextField id="outlined-basic"
                                           label="User Rights"
                                           variant="outlined"
                                           value={userRole}
                                           className="input"
                                           onChange={handleUserRoleChange}
                                />

                                <div className="action-buttons">
                                    <Button variant={"outlined"} className="save-button"
                                            startIcon={<PersonRemoveIcon/>}
                                            onClick={() => openDeleteDialogWindow()}>
                                        Delete User
                                    </Button>

                                    <Button variant={"outlined"} className="save-button"
                                            startIcon={<AddCircleOutlineIcon/>}
                                            onClick={() => saveUserChanges()}>
                                        Save
                                    </Button>
                                </div>
                            </>
                        </div>
                    }
                </div>
            </div>

            <Dialog
                sx={{'& .MuiDialog-paper': {width: '70%', maxHeight: 335}}}
                maxWidth="xs"
                open={openDeleteDialog}
            >
                <DialogTitle>Confirm user deletion</DialogTitle>
                <DialogContent dividers>
                    <p>Are You sure you want to delete user {userName}</p>
                    <br/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteDialog(false)}>
                        Cancel
                    </Button>
                    <Button onClick={() => deleteUser()}>Ok</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default UserManagement;
