import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { handleData } from "../../helpers/data-handler";
import ActionButtons from "./action-buttons";
import { useTableColumns } from "./useTableColumns";
import { toast } from 'react-toastify';
import NoDataView from "./no-data-view";
import { getParams } from "../../helpers/utils";

const API_URL = process.env.REACT_APP_API_URL;

const Table = ({selectedAppId, onRowClick, filterText, logEntriesCallback, selectedEntryChanged}) => {
    const [logEntries, setLogEntries] = useState([]);
    const [selectedEntry, setSelectedEntry] = useState();
    const [selectedRows, setSelectedRows] = useState([]);

    // Pagination state
    const [totalRows, setTotalRows] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);

    // Filtering state - WIP
    const [filteredEntries, setFilteredEntries] = useState(null);

    /**
     * Custom hook to manage table column configurations based on the table's width.
     */
    const {tableRef, columns} = useTableColumns();

    useEffect(() => {
        if (selectedEntryChanged) {
            setSelectedEntry(selectedEntryChanged.session_id);
            handleDeepLink(selectedEntryChanged.session_id);
        }
    }, [selectedEntryChanged])


    // Filtering - WIP
    useEffect(() => {
        const filteredItems = logEntries.filter(
            item => item.custom_fields.topic && item.custom_fields.topic.toLowerCase().includes(filterText.toLowerCase()),
        );

        setFilteredEntries(filteredItems);
    }, [filterText]);

    // Get Log data
    useEffect(() => {
        // console.log('useLogEntries effect triggered:', {appId, perPage});
        if (selectedAppId) {
            fetchLogEntries();
        }
    }, [selectedAppId, currentPage, perPage]);

    const fetchLogEntries = () => {
        const api = `${API_URL}/applications/${selectedAppId}?app_id=${selectedAppId}&page=${currentPage}&size=${perPage}`;
        const mockApi = '/entries.json';
        const deepLinkSessionId = getParams('sessionId');
        const hardcodedApi = "https://loggi-backend.sliplane.app/applications/1?app_id=1&page=1&size=10";

        handleData("GET", api)
            .then((response) => {
                setLogEntries(response.items);
                logEntriesCallback(response.items);
                setTotalRows(response.total);

                console.log(response.items)

                // Set selected entry based on URL param or default to the first entry
                const entry = deepLinkSessionId
                    ? response.items.find(item => item.session_id === deepLinkSessionId)
                    : response.items[0];

                if (entry) {
                    setSelectedEntry(entry.userId);
                    onRowClick(entry);
                }
            })
            .catch((error) => {
                console.error("Error fetching log entries:", error);
                toast.error('Failed to fetch log entries');
            });
    };

    const handlePageChange = (page) => {
        console.log('Page changed:', page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        console.log('Rows per page changed:', {newPerPage, page});
        setPerPage(newPerPage);
        setCurrentPage(page);
    };

    const onRowClicked = (row) => {
        onRowClick(row);
        setSelectedEntry(row.session_id);
        handleDeepLink(row.session_id);
    }

    const handleDeepLink = (sessionId) => {
        const url = new URL(window.location);
        url.searchParams.set('appId', selectedAppId);
        url.searchParams.set('sessionId', sessionId);
        window.history.pushState({}, '', url);
    };


    return (
        <div ref={tableRef}>
            <DataTable
                key={selectedAppId} // Force re-render when app changes
                columns={columns}
                data={filterText && filterText.length ? filteredEntries : logEntries}
                pagination
                // paginationServer > Enable on server > ATM pagination on the client side, provide all the data at once.
                selectableRows
                highlightOnHover
                pointerOnHover
                noDataComponent={<NoDataView filterText={filterText}/>}
                onRowClicked={onRowClicked}
                onSelectedRowsChange={({selectedRows}) => setSelectedRows(selectedRows)}
                paginationTotalRows={totalRows}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                paginationPerPage={perPage}
                paginationRowsPerPageOptions={[10, 20, 50, 100, 200]}
                subHeader
                subHeaderComponent={<ActionButtons selectedRows={selectedRows}/>}
                conditionalRowStyles={[
                    {
                        when: row => row.session_id === selectedEntry,
                        style: {
                            backgroundColor: '#F3F4F6',
                        },
                    },
                ]}
            />
        </div>
    );
};

export default React.memo(Table);
