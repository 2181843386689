import { FormControl, InputLabel, Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import React, { useState } from "react";

export default function FlagFilter({ filter }) {
    const [filterFlag, setFilterFlag] = useState('all');

    const setFlagFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterFlag(event.target.value);
    }

    return (
        <FormControl variant="filled" size="small" className='form-control'>
            <InputLabel id="demo-simple-select-filled-label">Filter Flag</InputLabel>
            <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                value={filterFlag}
                onChange={setFlagFilter}
            >
                <MenuItem value="all">Show All</MenuItem>
                <MenuItem value="Success">Success</MenuItem>
                <MenuItem value="Failed">Failed</MenuItem>
                <MenuItem value="Open">Open</MenuItem>
                <MenuItem value="Unknown">Unknown</MenuItem>
            </Select>
        </FormControl>
    )
}
