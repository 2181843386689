import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';

export default function ActionButtons({ selectedRows }) {
    const [disableButtons, setDisableButtons] = useState(true);

    useEffect(() => {
        // Enable buttons if there are selected rows; otherwise, disable
        setDisableButtons(!(selectedRows && selectedRows.length > 0));
    }, [selectedRows]);

    return (
        <div className='table-buttons'>
            <Button
                variant="outlined"
                size="small"
                startIcon={<DownloadIcon />}
                disabled={!(selectedRows && selectedRows.length === 1)}>
                Share
            </Button>
            <Button
                variant="outlined"
                size="small"
                startIcon={<DownloadIcon />}
                disabled={disableButtons}>
                PDF
            </Button>
            <Button
                variant="outlined"
                size="small"
                startIcon={<DownloadIcon />}
                disabled={disableButtons}>
                Excel
            </Button>
        </div>
    );
}
