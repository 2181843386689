import React, { useEffect, useState } from "react";
import { handleData } from "../../helpers/data-handler";
import AccountMenu from "./account-menu";
import { FormControl, Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { getParams } from "../../helpers/utils";

const API_URL = process.env.REACT_APP_API_URL;

export default function Header({selectedApp}) {
    const [applications, setApplications] = useState([]);
    const [selectedAppId, setSelectedAppId] = useState();

    useEffect(() => {
        getApplications();
    }, []);

    // Pass selected app to parent once it is loaded
    useEffect(() => {
        if (selectedAppId) selectedApp(selectedAppId);
    }, [selectedAppId]);

    const onHandleAppChange = (e) => {
        console.log('App changed:', e.target.value);
        setSelectedAppId(e.target.value);
        // setCurrentPage(1); // Reset to first page when changing apps
    }

    const getApplications = () => {
        const api = `${API_URL}/applications/`;
        const deepLinkAppId = getParams('appId');
        const apiMockLocal = '/applications.json';
        const hardcodedApi = "https://loggi-backend.sliplane.app/applications/"

        handleData("GET", api)
            .then((response) => {
                setApplications(response.items);

                // Set selected company based on URL param or default to the first entry
                const company = deepLinkAppId && response.items.find(item => item.id === Number(deepLinkAppId));

                if (company) {
                    setSelectedAppId(company.id);
                }
            })
    }

    return (
        <div className="header">
            <div className="logo">
                <h3 className="headline">Loggi - Application Log Viewer</h3>
            </div>

            <div className="user">
                {applications &&
                    <FormControl sx={{m: 1, minWidth: 220, backgroundColor: 'white'}} size="small">
                        <Select
                            value={selectedAppId || 'DEFAULT'}
                            placeholder="Select an App"
                            style={{padding: '5px 5px 0', fontSize: '16px'}}
                            variant="standard"
                            onChange={onHandleAppChange}
                        >
                            <MenuItem value="DEFAULT" disabled>Select an App</MenuItem>
                            {applications.map((app) => (
                                <MenuItem key={app.id} value={app.id.toString()}>{app.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                }

                <AccountMenu/>
            </div>
        </div>
    )
}
