import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ResultUpdateDialog } from "./result-update-dialog";
import { CategoryUpdateDialog } from "./category-update-dialog";
import MetaDataAccordion from "./meta-data-accordion";

export default function MetaData({entryData, sidebarSize, onPrevEntryClick, onNextEntryClick}) {
    const [openResultUpdateDialog, setOpenResultUpdateDialog] = useState(false);
    const [openCategoryUpdateDialog, setCategoryUpdateDialog] = useState(false);
    const [sessionResult, setSessionResult] = useState();
    const [categories, setCategories] = useState(['Cart Issues', 'Refund Request']);

    // Effect to update sessionResult when entryData changes
    useEffect(() => {
        setSessionResult();
        setCategories(['Cart Issues', 'Refund Request']);
    }, [entryData]); // Dependency on entryData

    const onResultClick = () => {
        setOpenResultUpdateDialog(true);
    };

    const onCategoryEditClick = () => {
        setCategoryUpdateDialog(true);
    }

    const handleCloseCategoryEditDialog = (newValue) => {
        setCategoryUpdateDialog(false);

        if (newValue) {
            setCategories(newValue);

            toast.success('Category updated!', {
                position: "bottom-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    const handleCloseResultUpdateDialog = (newValue) => {
        setOpenResultUpdateDialog(false);

        if (newValue) {
            setSessionResult(newValue);

            toast.success('Result status updated!', {
                position: "bottom-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

    return (
        <>
            <MetaDataAccordion
                entryData={entryData}
                onResultClick={onResultClick}
                onCategoryEditClick={onCategoryEditClick}
                sessionResult={sessionResult}
                categories={categories}
                sidebarSize={sidebarSize}
                onPrevEntryClick={onPrevEntryClick}
                onNextEntryClick={onNextEntryClick}
            />

            {openResultUpdateDialog &&
                <ResultUpdateDialog
                    open={openResultUpdateDialog}
                    onClose={handleCloseResultUpdateDialog}
                    value={sessionResult}
                />
            }

            {openCategoryUpdateDialog &&
                <CategoryUpdateDialog
                    open={openCategoryUpdateDialog}
                    onClose={handleCloseCategoryEditDialog}
                    value={categories}
                />
            }
        </>
    )
}
