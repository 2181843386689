import React, { useState } from "react";
import {
    Box,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel, OutlinedInput, Select, useTheme
} from "@mui/material";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";

const options = [
    {label: 'Cart Issues', color: 'white', back: '#aa1e1e'},
    {label: 'Refund Request', color: 'white', back: '#fbad49'},
    {label: 'Login Issues', color: 'white', back: 'black'},
    {label: 'Shipping Delay', color: 'black', back: 'yellow'},
    {label: 'Payment Inquiry', color: 'white', back: 'blue'},
    {label: 'Order Tracking', color: 'white', back: 'grey'},
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 8.5 + ITEM_PADDING_TOP,
            width: 150,
        },
    },
};


export function CategoryUpdateDialog({onClose, value: valueProp, open}) {

    const [category, setCategory] = useState(valueProp);

    const handleChange = (event) => {
        setCategory(event.target.value);
    };

    return (
        <Dialog
            sx={{'& .MuiDialog-paper': {width: '70%', maxHeight: 335}}}
            maxWidth="xs"
            open={open}
        >
            <DialogTitle>Update Category</DialogTitle>
            <DialogContent dividers>
                <FormControl sx={{width: "100%"}}>
                    <InputLabel id="demo-multiple-chip-label">Category</InputLabel>
                    <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={category}
                        onChange={handleChange}
                        input={<OutlinedInput id="select-multiple-chip" label="Category"/>}
                        MenuProps={MenuProps}
                        renderValue={(selected) => (
                            <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                {selected.map((value) => {
                                    const option = options.find(opt => opt.label === value);
                                    return (
                                        <Chip
                                            key={value}
                                            label={value}
                                            size="small"
                                            style={{
                                                color: option ? option.color : 'default',
                                                backgroundColor: option ? option.back : 'default'
                                            }}
                                        />
                                    );
                                })}
                            </Box>
                        )}
                    >
                        {options.map((option) => (
                            <MenuItem
                                key={option.label}
                                value={option.label}
                                style={{fontWeight: category.includes(option.label) ? '500' : '400'}}
                            >
                                {option.label}
                            </MenuItem>

                        ))}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose(null)}>
                    Cancel
                </Button>
                <Button onClick={() => onClose(category)}>Ok</Button>
            </DialogActions>
        </Dialog>
    );
}

CategoryUpdateDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
};
