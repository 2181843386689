import React, { useState } from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CategoryFilter from "./category-filter";
import ResultFilter from "./result-filter";
import LanguageFilter from "./language-filter";
import FlagFilter from "./flag-filter";
import Divider from "@mui/material/Divider";
import SearchIcon from '@mui/icons-material/Search';

import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

export default function Filters({onFilterText}) {
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

    const [dateTimeFilterFrom, setDateTimeFilterFrom] = useState('');
    const [dateTimeFilterTo, setDateTimeFilterTo] = useState('');

    const handleClear = () => {
        if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText('');
            onFilterText('');
        }
    };

    const handleFilterText = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterText(event.target.value);
        onFilterText(event.target.value);
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="filters-wrapper">
                <div className="filters-row">
                    <div className="filters-row-dropdowns-wrapper">
                        <CategoryFilter/>

                        <ResultFilter/>

                        <LanguageFilter/>

                        <FlagFilter/>
                    </div>

                    <div className="filters-row-dates-wrapper">
                        <DateTimePicker
                            label="From"
                            className="date-picker"
                            slotProps={{
                                textField: {size: 'small', variant: 'filled'},
                                actionBar: {actions: ['cancel', 'accept', 'today', 'clear']}
                            }}
                            onChange={(newValue) => setDateTimeFilterFrom(newValue)}
                        />

                        <DateTimePicker
                            label="To"
                            className="date-picker"
                            slotProps={{
                                textField: {size: 'small', variant: 'filled'},
                                actionBar: {actions: ['cancel', 'accept', 'today', 'clear']}
                            }}
                            onChange={(newValue) => setDateTimeFilterTo(newValue)}
                        />
                    </div>
                </div>

                <div className="filters-row">
                    <TextField
                        fullWidth
                        label="Search for content, sessionID, topic"
                        variant="filled"
                        value={filterText}
                        onChange={handleFilterText}
                        size="small"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={handleClear}
                                        edge="end"
                                    >
                                        <SearchIcon/>
                                    </IconButton>
                                    <Divider sx={{height: 28, m: 0.5, marginLeft: 1.5}} orientation="vertical"/>
                                    <IconButton
                                        onClick={handleClear}
                                        edge="end"
                                    >
                                        <HighlightOffIcon/>
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
            </div>
        </LocalizationProvider>
    )
}
